<template>
  <main>
    <div v-if="buildings" class="subnav-detail">
      <router-link
        class="btn btn-primary float-end"
        :to="{ name: 'CreateBuilding' }"
      >
        Create Building
      </router-link>
      <h4>{{ user.displayName }}'s Buildings</h4>
      <div v-for="building in buildings" :key="building.name">
        <router-link
          :to="{ name: 'BuildingDetails', params: { buildingId: building.id } }"
        >
          <div class="single">
            <div class="col">
              <div class="building-thumb">
                <img :src="building.image1URLThumb" />
              </div>
            </div>
            <div class="col">
              <div class="name">
                {{ building.name }}
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </main>
</template>

<script>
import getCollection from "@/composables/getCollection";
import getUser from "@/composables/getUser";

export default {
  name: "Home",
  setup() {
    const { user } = getUser();
    const { error, documents: buildings } = getCollection(
      "buildings",
      [["managerId", "==", user.value.uid]],
      [["name", "asc"]]
    );

    return { user, error, buildings };
  }
};
</script>
